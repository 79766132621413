import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';

import HomePage from './pages/Home/HomePage';
import VideoDetailPage from './pages/VideoDetail/VideoDetailPage';

import './App.css';

function App() {
  return (
    <ChakraProvider>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<HomePage />}></Route>
          <Route path='/:token' element={<VideoDetailPage />}></Route>
        </Routes>
      </BrowserRouter>
    </ChakraProvider>

  );
}

export default App;
