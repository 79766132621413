import { Modal, ModalOverlay, ModalHeader, ModalContent, ModalCloseButton, ModalBody, FormControl, Input, FormLabel, ModalFooter, Button, AspectRatio } from "@chakra-ui/react";
import { useState } from "react";
import CheckVideo from "../../utils/CheckVideo";

interface IProps {
  initialRef: any;
  finalRef: any;
  isOpen: any;
  onClose: any;
  createVideo: any;
}

function CreateVideoModalComponent(props: IProps) {
  const [buttonLabel, setButtonLabel] = useState('Extrair');
  const [isVideo, setIsVideo] = useState(false);
  const [url, setUrl] = useState('')

  const handleChangeUrl = (event: any) => {
    setUrl(event.target.value)
  }

  const onClick = async () => {
    if (buttonLabel === 'Extrair') {
      const res = await CheckVideo(url);
      if (res) {
        setIsVideo(true);
        setButtonLabel('Criar')
      }
    } else if (buttonLabel === 'Criar') {
      await props.createVideo(url);
      props.onClose()
    }
    
  }

  return (
    <Modal
      initialFocusRef={props.initialRef}
      finalFocusRef={props.finalRef}
      isOpen={props.isOpen}
      onClose={props.onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Criar novo vídeo</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <FormControl>
            <FormLabel>Vídeo URL</FormLabel>
            <Input ref={props.initialRef} value={url} onChange={handleChangeUrl} placeholder='Url' />
          </FormControl>
          {isVideo && (
            <AspectRatio>
              <video controls style={{ paddingTop: '30px'}}>
                <source src={url} />
              </video>
            </AspectRatio>
          )}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme='blue' mr={3} onClick={onClick}>
            {buttonLabel}
          </Button>
          <Button onClick={props.onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default CreateVideoModalComponent;
