/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Center, Card, CardBody, Stack, Text, Divider, Heading, Flex, Box, StackDivider, useToast } from '@chakra-ui/react';
import moment from 'moment';

import HeaderComponent from '../../components/Header/HeaderComponent';
import VideoAPI from '../../services/VideoApi';
// import { useLocation, useNavigate } from 'react-router-dom';

function VideoDetailPage() {
  const [videoDetail, setVideoDetail] = useState<any>({});

  const videoApi: VideoAPI = new VideoAPI();
  const params = useParams()
  const navigate = useNavigate()
  const toast = useToast()

  useEffect(() => {
    fetchVideo();
  }, []);

  const fetchVideo = async () => {
    await videoApi.getVideo(params.token ?? '').then((response) => {
      console.log(response.data)
      setVideoDetail(response.data);
    }).catch(() => {
      toast({ title: 'Vídeo não encontrado', status: 'error', isClosable: true })
      navigate('/')
    });

  }

  return (
    <div>
      <HeaderComponent />
      <Center paddingTop={'10px'} paddingBottom={'50px'} flexDirection={'column'}>
        <Card width='1000px'>
          <CardBody>
            <Heading size='md'>ID: {videoDetail.token}</Heading>
            <Stack mt='6' spacing='3'></Stack>
            <Stack mt='6' spacing='3'></Stack>
            <Stack divider={<StackDivider />} spacing='4'>
              <Box>
                <Heading size='xs' textTransform='uppercase'>
                  Id
                </Heading>
                <Text pt='2' fontSize='sm'>
                  {videoDetail.token}
                </Text>
              </Box>
              <Box>
                <Heading size='xs' textTransform='uppercase'>
                  Status
                </Heading>
                <Text pt='2' fontSize='sm'>
                  {videoDetail.status}
                </Text>
              </Box>
              <Box>
                <Heading size='xs' textTransform='uppercase'>
                  SRT
                </Heading>
                <Text pt='2' fontSize='sm'>
                  {videoDetail.srt ? <a href={videoDetail.srt}>{videoDetail.srt}</a> : <Text>Indisponível</Text>}
                </Text>
              </Box>
              <Box>
                <Heading size='xs' textTransform='uppercase'>
                  Data de criação:
                </Heading>
                <Text pt='2' fontSize='sm'>
                  {moment(videoDetail.created).format('DD/MM/YYYY HH:mm:ss')}
                </Text>
              </Box>
              <Box>
                <Heading size='xs' textTransform='uppercase'>
                  Data de atualização:
                </Heading>
                <Text pt='2' fontSize='sm'>
                  {moment(videoDetail.updated).format('DD/MM/YYYY HH:mm:ss')}
                </Text>
              </Box>
              <Box>
                <Heading size='xs' textTransform='uppercase'>
                  Vídeo submetido:
                </Heading>
                <Flex maxW={'100%'} justifyContent={'space-between'}>
                  <video controls src={videoDetail.source} style={{ paddingTop: '30px', maxWidth: '500px', maxHeight: '400px' }}>
                    <source src={videoDetail.source} />
                  </video>
                  {videoDetail.file && (
                    <video controls src={videoDetail.file} style={{ paddingTop: '30px', maxWidth: '400px', maxHeight: '400px' }}>
                      <source src={videoDetail.file} />
                    </video>
                  )}
                </Flex>
              </Box>
            </Stack>
          </CardBody>
          <Divider />
        </Card>
      </Center>
    </div>
  );
}

export default VideoDetailPage;
