/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Button, Center, Flex, Heading, useDisclosure, useToast } from '@chakra-ui/react';

import CreateVideoModalComponent from '../../components/CreateVideoModal/CreateVideoModalComponent';
import RequestsTableComponent from '../../components/RequestsTable/RequestsTableComponent';
import HeaderComponent from '../../components/Header/HeaderComponent';
import VideoAPI from '../../services/VideoApi';

function HomePage() {
  const videoApi: VideoAPI = new VideoAPI();
  const { isOpen, onOpen, onClose } = useDisclosure()

  const initialRef = React.useRef(null)
  const finalRef = React.useRef(null)
  const toast = useToast()


  const [videos, setVideos] = useState([]); 

  useEffect(() => {
    fetchVideos();

    const interval = setInterval(() => {
      fetchVideos();
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  const fetchVideos = async () => {
    await videoApi.login();
    const response = await videoApi.listVideos();
    setVideos(response.data.records);
  }

  const createVideo = async (url: string) => {
    console.log(videoApi);
    await videoApi.createVideo(url).catch(() => {
      toast({
        title: 'Problema ao criar novo vídeo',
        status: 'error',
        isClosable: true,
      })
    });
    await fetchVideos();
  }

  return (
    <div>
      <CreateVideoModalComponent finalRef={finalRef} initialRef={initialRef} isOpen={isOpen} onClose={onClose} createVideo={createVideo}/>
      <HeaderComponent />
      <Center paddingTop={'50px'} flexDirection={'column'}>
        <Flex justifyContent={'space-between'} width={'100%'} maxW={'1000px'}>
          <Heading paddingBottom={'50px'}>Lista de vídeos</Heading>
          <Button colorScheme='blue' size='md' onClick={onOpen}>Criar vídeo</Button>
        </Flex>
        <RequestsTableComponent videos={videos} />
      </Center>
    </div>
  );
}

export default HomePage;
