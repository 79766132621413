import { Box, Center, Skeleton, Text } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";

interface IProps {
  src?: string;
}

function ThumbnailComponent(props: IProps) {

  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const sourceRef = useRef<HTMLSourceElement>(null)
  const videoRef = useRef<HTMLVideoElement>(null)

  useEffect(() => {

    const source = sourceRef.current
    if (!source) return

    const handleError = () => {
      setIsError(true)
    }

    source.addEventListener('error', handleError)

    return () => {
      source.removeEventListener('error', handleError)
    }
  }, [])

  useEffect(() => {
    const video = videoRef.current
    if (!video) return

    const handleLoaded = () => {
      setIsLoading(false)
    }

    video.addEventListener('loadeddata', handleLoaded)

    return () => {
      video.removeEventListener('loadeddata', handleLoaded)
    }
  }, [])

  if (!props.src || isError) {
    return (
      <Center h="60px" w="100px" background="gray.200" p="2" rounded="3">
        <Text textAlign="center" fontSize="sm" color="gray.500">
          Sem thumbnail
        </Text>
      </Center>
    )
  }

  return (
    <Box h="60px" w="100px" rounded="1">
      {isLoading && <Skeleton h="60px" w="100px" />}

      <video
        ref={videoRef}
        preload="metadata"
        style={isLoading ? { display: 'none' } : undefined}
      >
        <source ref={sourceRef} src={`${props.src}#t=1`} />
      </video>
    </Box>
  );
}

export default ThumbnailComponent;
