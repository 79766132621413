import { TableContainer, Table, Thead, Tr, Th, Td, Tbody, Button } from "@chakra-ui/react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import ThumbnailComponent from "../Thumbnail/ThumbnailComponent";

interface IProps {
  videos?: any[];
}

function RequestsTableComponent(props: IProps) {
  const navigate = useNavigate();

  useEffect(() => {
    console.log('Update Videos')
  }, [props.videos]);

  return (
    <TableContainer width={'1000px'}>
      <Table variant='striped' colorScheme='blue' size={'lg'}>
        <Thead>
          <Tr>
            <Th>Thumbnail</Th>
            <Th>ID</Th>
            <Th>Status</Th>
            <Th>Ação</Th>
          </Tr>
        </Thead>
        <Tbody>
          {props.videos && props.videos.map((video) => (
            <Tr>
              <Td>
                <ThumbnailComponent src={video.source}/>
              </Td>
              <Td>{video.token}</Td>
              <Td>{video.status}</Td>
              <Td>
                <Button colorScheme='blue' size='md' onClick={() => { navigate(`/${video.token}` )}}>
                  Visualizar
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}

export default RequestsTableComponent;
