import Axios from 'axios';

class VideoAPI {
  private Api = Axios.create({ baseURL: 'https://api2.assista.dev.v4h.cloud/api/v1' });
  private accessToken = '';

  login = async () => {
    return this.Api.post('/auth/org', { token: '589ce8ef-f936-4ef8-959f-e32f05579f4e', secret: 'b43e29782eab6b045483b22c28e60510' }).then((res) => {
      this.accessToken = res.data.accessToken;
      return res.data;
    });
  }

  listVideos = async () => {
    return this.Api.get('/medias', { headers: { Authorization: `Bearer ${this.accessToken}` } });
  }

  createVideo = async (url: string) => {
    const loginResponse = await this.login();
    return this.Api.post('/medias', { url, summarize: true }, { headers: { Authorization: `Bearer ${loginResponse.accessToken}` } });
  }

  getVideo = async (token: string) => {
    const loginResponse = await this.login();
    return this.Api.get(`/medias/${token}`, { headers: { Authorization: `Bearer ${loginResponse.accessToken}` } });
  }

}

export default VideoAPI;