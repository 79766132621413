import { Box, Heading, Flex, Stack, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

function HeaderComponent(props: any) {
  const navigate = useNavigate();

  const onClickBegin = () => {
    navigate('/');
  }

  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      padding={6}
      bg="#2B6CB0"
      color="white"
      {...props}
    >
      <Flex align="center" mr={5}>
        <Heading as="h1" size="lg" letterSpacing={"tighter"}>
          Libras VoD
        </Heading>
      </Flex>

      <Stack
        direction={{ base: "column", md: "row" }}
        display={{ base: "block", md: "flex" }}
        width={{ base: "full", md: "auto" }}
        alignItems="center"
        flexGrow={1}
        mt={{ base: 4, md: 0 }}
      >
        <Box onClick={onClickBegin} height={'100%'} style={{ cursor: 'pointer' }}>
          <Text>Inicio</Text>
        </Box>
      </Stack>

      <Box
        display={{ base: "block", md: "block" }}
        mt={{ base: 4, md: 0 }}
      >
      </Box>
    </Flex>
  );
}

export default HeaderComponent;
