import Axios from 'axios';

const CheckVideo = async (url: string): Promise<boolean> => {
  return !!await Axios.head(url)
    .then((res) => {
      console.log(res);
      if (res.headers['content-type']?.toString().split('/')[0] === 'video') return true;
    })
    .catch(() => {
      return false;
    });
}

export default CheckVideo;